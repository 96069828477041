import React, { CSSProperties, FunctionComponent, useMemo } from 'react'

import classes from './Spacer.module.scss'

export const Spacer: FunctionComponent<{
  height?: string
}> = ({ height = '1unit' }) => {
  const style = useMemo<CSSProperties>(() => ({ height: toCss(height) }), [height])

  return <div style={style} />
}

export const Separator: FunctionComponent<{
  height?: number
}> = ({ height = 1 }) => {
  const strHeight = toCss(`${height}unit`)
  const style = useMemo<CSSProperties>(() => ({ marginTop: strHeight, marginBottom: strHeight }), [strHeight])

  return <div className={classes.separator} style={style} />
}

export const Expander: FunctionComponent<{
  minHeight?: string
  maxHeight?: string
}> = ({ minHeight, maxHeight }) => {
  const style = useMemo<CSSProperties>(() => ({ minHeight: toCss(minHeight), maxHeight: toCss(maxHeight), flex: '1' }), [minHeight, maxHeight])

  return <div style={style} />
}

function toCss(value?: string) {
  if (value?.endsWith('unit')) return `${parseFloat(value) * 18}px`
  return value
}
